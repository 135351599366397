/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jun 25, 2018, 2:25:39 PM
    Author     : window5
*/
html, body {
    margin: 0;
    padding: 0;
}
.catSide li .linkCat:hover {
    background: #ff9500;
}
.catSide li{
    width: 100%;
}
.catSide li .linkCat {
    background: #efefef;
    padding: 10px 25px;
    width: 100%;
    display: block;
    color: #333;
    margin-bottom: 2px;
    font-weight: 700;
}
.boldText {
    font-size: 20px;
    font-weight: 800;
}
.section-description .nav li a {
    color: #000;
}
.section-description .nav li.active a {
    color: #fff;
}
.pt-5 {
    padding-top: 3rem;
}
.pb-5 {
    padding-bottom: 3rem;
}
/* = Nav Arrows
---------------------------------------- */
.centered{
    text-align: center;
    margin-top: 10px;
}
.demo-book{
    overflow: hidden !important;
}
.page-footer {
    background: #000 !important;
    z-index: 99999;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: #fff !important;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    padding-top: 6px;
    bottom: 0px;
}
.containers {
    padding: 15px 5%;
}
#fb7{
    margin: 0 10%;
}
#fb7 .fb7-menu#fb7-right {
    float: right;
    width: auto;
    margin: 15px 6px 0 0;
    position: fixed;
    right: 0;
    top: -5px;
}

#fb7 .fb7-menu#fb7-right li {
    background:none;
}


#fb7 .fb7-menu li.fb7-goto {
    position: relative;
    width: auto;
    margin: -6px 4px 0px 0;
    padding: 6px 0 0 10px;
}

#fb7 .fb7-menu li.fb7-goto #fb7-label-page-number {
    font-family:Arial,sans-serif;
    display:inline;
    float: left;
    margin: 6px 5px 0 0;
    line-height: 12px;
    font-size: 10px;
    font-weight: bold;
    color: #5f6f7b;
    -webkit-font-smoothing: antialiased;
    width:auto;
}
#fb7 .fb7-menu li.fb7-goto input[type="text"] {
    color: #000000;
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #566C43;
}

#fb7 .fb7-menu li.fb7-goto input[type='text'] {
    height: 31px;
    width: 100px;
    padding: 0 55px 0 7px;
    text-align: center;
    font-size: 14px;
    font-family:Nunito;
    font-weight:bold;
    display:inline;
    border-radius:25px;
    box-shadow:inset 1px 2px 3px #CCCCCC;
}

#fb7 .fb7-menu li.fb7-goto input[type='text'].focus_input  {

    box-shadow:0 0 7px 2px #97dcea;
}

#fb7 .fb7-menu li.fb7-goto button {
    text-shadow:none;
    font-family:Nunito,sans-serif;
    position: absolute;
    right: 1px;
    top: 7px;
    height:29px;
    width:40px;
    border:none;
    border-top-right-radius:3em;
    border-bottom-right-radius:3em;

    /*border:1px solid #EBEEF0;/*/
    display:inline;
    font-size: 11px;
    text-align:center;
    font-weight: bold;
    padding: 0 1px;
    background: -webkit-linear-gradient(#f7a053,#f16951);
    background: -o-linear-gradient(#f7a053,#f16951);
    background: linear-gradient(#f7a053,#f16951);
    background: linear-gradient(#f7a053,#f16951);
    color: #803900;
    cursor:pointer;
}

/*#page.mobile .fb7-nav-arrow.next { right: -24px; }*/

.text-white{
    color: #fff;
}
.stf__parent {
    margin: 5px auto;
}
.imgBook{
    width:100%;
}
.embed-responsive-16by9::before {
    padding-top: 47.5% !important;
}
img.w-100z {
    max-width: 36px;
}
.mrr-1{
    margin-right: 5px;
}
a.close-video-im:hover {
    opacity:0.75;
    transition: .5s;
}

.content {
    padding: 15rem 0 0 5rem !important;
}
.content ol li {
    margin-top: 1rem !important;
}
.loadermy{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999999;
}
.video-player {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background: #fbfbfb;
    display: none;
}
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}
.close-video {
    height: 60px;
    width: 60px;
    position: absolute;
    right: 1rem;
    top: 0;
    z-index: 1100;
}
ul#fb7-center {
    /* margin: 0 auto; */
    /* transform: translate(-121%, 0); */
    display: table;
    margin: 0 auto;
}
.ckDLXL {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    min-height: 0vh !important;
    font-size: 26px !important;
}
ul#fb7-center li {
    float: left;
}
.fullscreen-video-im img {
    width: 36px;
}
.btf-5 {
    min-height: 70px;
}
.pointer {
    cursor: pointer;
}
#fb7-about ul li {
    color: black !important;
}

#fb7 h1 {
    color: #f7a154 !important;
    font-size: 30px;
    font-weight: bold !important;
    text-shadow: 1px 2px #000;
    padding: 0 0 0px 0px;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    margin: 0 0 2% 0;
}
#fb7 .description, #fb7 .description p, #fb7 .description span {
    font-size: 11px !important;
    line-height: 16px;
}
.gsRWYV {
    cursor: pointer;
    text-align: center !important;
    margin: 0 auto;
    width: 100%;
}
img.product-list-primary-img {
    --d: 10px;
    --a: 20deg;
    --x: 10px;
    width: 160px;
    /*    aspect-ratio: 1.1;  you can use 1 as ratio but I found 1.1 a little better */
    object-fit: cover;
    padding-block: var(--d);
    transform: perspective(400px) rotateY(calc(var(--_i,1)*var(--a)));
    outline: var(--d) solid #0008;
    outline-offset: calc(-1*var(--d));
    --_d: calc(100% - var(--d));
    --_l: 0px;
    --_r: 0px;
    clip-path: polygon(
        var(--_l) calc(var(--_d) - var(--x)),
        var(--_l) calc(var(--d)  + var(--x)),
        var(--d) var(--d),var(--_d) var(--d),
        calc(var(--_d) + var(--_r)) calc(var(--d)  + var(--x)),
        calc(var(--_d) + var(--_r)) calc(var(--_d) - var(--x)),
        var(--_d) var(--_d),var(--d) var(--_d)
        );
    transition: transform .3s,--_r .15s,--_l .15s .15s;
    transition-timing-function: linear;
    cursor: pointer;
}
img.product-list-primary-img:hover, img.product-list-primary-img {
     --_l: var(--d);
    --_r: var(--d);
    --_i: -1;
    transition-delay: 0s,.15s,0s;
}
img.product-list-primary-img:hover {
    --_l: 0px;
    --_r: 0px;
    --_i: 1;
    transition-delay: 0s,0s,.15s;
}
@media (max-width: 720px){
    img.w-100z {
        max-width: 36px;
    }
    .searchBar {
    display: none;
}
.dropdown-lgo-small {
    display: block;
    margin-top: -5px;
}
.dropdown-lgo-small .navbar-nav .open .dropdown-menu {
    margin-top: 0;
}
    ul#fb7-center{
        margin: 0 10px;
    }
    #fb7{
        margin: 0 2.5%;
    }
    .product-list-name {
        font-size: 13px;
        line-height: 16px;
    }
    .swiper-wrappers li.swiper-slidess {
        padding: 0 5px;
    }
    .slick-prev {
        left: 5px !important;
    }
    .slick-next {
        right: 20px !important;
    }
    .navbar-fixed-top {
        position: fixed !important;
        background: #fff !important;
        min-width: 30px !important;
    }
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-top: 0 !important;
    }
    .navbar a.navbar-brand {
        margin-bottom: 0 !important;
    }
    .mt-xs-5 {
        margin-top: 12px;
    }
    
section.section-breadcu {
    padding-top: 10px;
}
section.loginSection {
    margin-top: 0;
}
}
