/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jun 25, 2018, 2:25:39 PM
    Author     : window5
*/

.content{
    padding:15rem 0 0 5rem !important;
    ol{
        li{
            margin-top:1rem !important;
        }
    }
}

.video-player{
    position:absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%; 
    z-index: 1000;
    background: #fbfbfb;
    display: none;
}
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.close-video{
    height: 60px;
    width: 60px;
    position: absolute;
    right: 1rem;
    top:0;
    z-index: 1100;
}
.pointer{
    cursor: pointer;
}

#fb7-about{
    ul{
        li{
            color:black !important;
        }
    }
}