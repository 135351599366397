/*=======================
Name: Goshop - Mini eCommerce Shop Templates
Author: Team90Degree
Author Url: Team90Degree.com
Version: 1.0.0
 ========================*/

/*=====  [Table of contents] ===== 
1. Header Style
2. slider style
3. catalog style
4. product style
5. about style
6. testimonials style
7. footer style
8. Preloader style
*/
a {
    cursor: pointer;
}
.text {
    display: inline;
    width: 90%;
}
.mr-2{
    margin-right: 5px;
}
.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
    font-weight: 600;
}
.form-control[disabled]{
    background: #f5f5f5;
}
.img-101 img {
    width: 25%;
}
.searchBar .btn {
    box-shadow: none !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    text-transform: uppercase;
}
div#loom-companion-mv3 {
    display: none;
}
.searchBar input {
    border: 1px solid #d8d8d8;
    background: #fff;
}
.searchBar .btn-default {
    color: #fff;
    background-color: #efb920;
    border-color: #efb920;
}
.library-content {
    margin-top: 6rem;
    margin-bottom: 3rem;
    font-family: "Trebuchet MS", Trebuchet, Arial, Helvetica, sans-serif;
    font-size: 2.4rem !important;
    color: rgb(140, 140, 140);
    text-align: center;
    line-height: normal;
}
.library-content p {
    font-size: 2.4rem !important;
}
.message-box-f._success._failed i {
    font-size: 8rem;
    color: red;
}
.message-box-f {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    padding: 10px 15px;
    display: inline-block;
    border: 1px solid #ff0000;
    color: #ff0000;
    background-color: #ff00000d;
}
.bg-primarys {
    background: #98efaeb8;
}
.bg-failed {
    background: red;
    color: #fff;
}
.m-0 {
    margin: 0!important;
}
.p-5 {
    padding: 3rem!important;
}
.mb-5, .my-5 {
    margin-bottom: 3rem!important;
}
.mt-5, .my-5 {
    margin-top: 3rem!important;
}
.width-100{
    width: 100%;
}
.width-40{
    width: 40%;
}
.bg-blue-dark {
    background: #c1e4ff;
}
.btn-100 {
    display: inline-block;
    padding: 12px 12px;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    background: black;
    text-transform: uppercase;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.wl-100{
    width: 100%;
    background: #fff;
    border: 1px solid #000;
    float: left;
    height: 22px;
}
@media (min-width: 768px){
    .p-md-0 {
        padding: 0!important;
    }
}
.startList {
    background: #2c96ea;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    text-align: left;
    text-transform: capitalize;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}
ul.ulList li {
    list-style: auto;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 12px;
}
section.section-mins {
    border-top: 1px solid #f0f0f0;
    margin-top: 65px;
    padding: 40px 7%;
}
section.section-buy {
    margin-top: 65px;
    padding: 40px 7%;
    border-top: 1px solid #f0f0f0;
}
.mb-5 {
    margin-bottom: 3rem;
}
.message-box {
    padding: 2rem 2.5rem 2rem 3.5rem;
    position: relative;
}
.cart-pic {
    height: 100%;
    width: 100%;
    max-width: 60px;
    -webkit-transition: filter .2s;
    transition: filter .2s;
    -webkit-transition-property: -webkit-filter;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
}
.two-line {
    -webkit-line-clamp: 2;
}
.cart-item-card {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    background: #fff;
    margin: 30px 0;
}
.btn-success {
    color: #fff;
    background-color: #ffa628;
    border-color: #ff9500;
    border-radius: 0;
    font-weight: 700;
    font-size: 15px;
    text-transform: capitalize !important;
}
button.btnLink {
    padding: 0;
    background: 0;
    border: 0;
    color: #7a5bc7;
    text-decoration: underline;
}
.save-payment-information-security-message p {
    font-size: 14px;
    color: #767676;
}
input[type="radio"], input[type="checkbox"] {
    margin: 4px 10px 0px;
    margin-top: 1px \9;
    line-height: normal;
}
.p-2 {
    padding: 2rem;
}
img.imgcc {
    width: 16px;
}
.border-bottom {
    border-bottom: 1px solid rgb(230, 230, 230);
}
.cart-item-card-top {
    padding: 20px;
}
.mb-2{
    margin-bottom: 0.8rem;
}
.text-right{
    text-align: right;
}
.p-0{
    padding: 0;
}
.m-0{
    margin: 0;
}
.price-summary-container {
    background-color: rgb(255, 255, 255);
    font-family: "Trebuchet MS";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 22px;
    margin: 0px;
    border-top: 1px solid rgb(230, 230, 230);
    padding: 15px;
}
.price {
    font-weight: 800;
    text-align: right;
}
.title-text {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
}
.one-line {
    -webkit-line-clamp: 1;
}
.truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
.message-box .title {
    display: block;
    font-weight: 700;
}
.message-box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    padding: 10px 15px;
    display: inline-block;
    border: 1px solid #1e9691;
    color: #195a5a;
    background-color: #e9f5f4;
}
.message-box.icon:before {
    content: "";
    background-image: url('../img/device_pair.png');
    background-image: url('../img/device_pair.svg'),linear-gradient(transparent,transparent);
    background-repeat: no-repeat;
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    left: 2.5rem;
    margin-right: 2rem;
}
.message-box.icon .inner-wrapper {
    padding-left: 50px;
}
.message-box .inner-wrapper {
    position: relative;
    max-width: 1140px;
    margin: 0 auto;
}
.purchase-path-container .print-book-message-container .title {
    display: block;
    font-weight: 700;
}
.section-title {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 2rem;
    line-height: 1;
    margin: 0 0 25px;
    padding: 0 0 10px;
    text-transform: capitalize;
}
.serror {
    border: 1px solid red !important;
}
li.dropdown-divider {
    border-top: 1px solid #ccc;
}
section.loginSection {
    margin-top: 30px;
    background-image: url('../img/prism_large.png');
    background-repeat: repeat-x;
    background-size: inherit;
    background-color: #e6e6e6;
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 5%;
}

.text-right{
    text-align: right;
}

.loginMenu {
    background-color: #2c96ea;
    border: 1px solid transparent;
    color: #fff !important;
    border-radius: 0;
    padding: 8px 20px !important;
}

.navbar-brand img {
    margin-top: 0px;
}

.dropdown-menu-right {
    max-width: 200px;
    right: 0;
    top: 55px;
    padding: 0px 0px;
    min-height: 200px;
}
.d-none {
    display: none;
}
.dropdown-lgo {
    width: auto;
    float: right;
}

div#myNavbar {
    float: right;
}

.user-profile img {
    width: 40px;
    height: 40px;
    object-fit: fill;
}

.ch_prof:hover .ch_menuss{
    display: block;
}

.register-legal {
    font-size: 12px;
    color: #9b9b9b;
    margin: 10px 0;
    line-height: 20px;
}

button.width-100 {
    width: 100%;
    margin: 0 !important;
    border: 2px solid #000;
}

.or-separator {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 25px;
    line-height: 2.5rem;
    font-family: "Rakuten Sans UI","Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    text-align: center;
    overflow: hidden;
}

.or-separator span {
    line-height: 0;
    line-height: 0;
    display: inline-block;
    position: relative;
}

.or-separator span:before {
    right: 100%;
    margin-right: 10px;
}

.or-separator span:after, .or-separator span:before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: #ccc;
    width: 600px;
}

.or-separator span:after {
    left: 100%;
    margin-left: 10px;
}

.sign-in-options .partner-sign-in {
    display: inline;
    margin-bottom: 10px;
}

.primary-provider .sign-in-options .partner-sign-in .apple, .primary-provider .sign-in-options .partner-sign-in .facebook, .primary-provider .sign-in-options .partner-sign-in .feltrinelli, .primary-provider .sign-in-options .partner-sign-in .google, .primary-provider .sign-in-options .partner-sign-in .ibs, .primary-provider .sign-in-options .partner-sign-in .libraccio {
    -webkit-transition: background-color .2s,color .2s;
    transition: background-color .2s,color .2s;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Rakuten Sans UI","Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    display: inline-block;
    background: 0 0;
    font-style: normal;
    font-size: 1.4rem;
    color: #fff;
    min-height: 35px;
    min-width: 70px;
    padding: 5px 10px;
    border-width: 3px;
    border-style: solid;
    border-radius: 0;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    padding: 5px;
    font-weight: 400;
    color: #000;
    background-color: #fff;
    border: 1px solid #989898;
    font-size: 1.3rem;
}

.sign-in-options .partner-sign-in .partner-option {
    margin-bottom: 15px;
}

.sign-in-options .partner-sign-in .kobo-link {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}

.primary-provider .sign-in-options .partner-sign-in .apple:before, .primary-provider .sign-in-options .partner-sign-in .facebook:before, .primary-provider .sign-in-options .partner-sign-in .feltrinelli:before, .primary-provider .sign-in-options .partner-sign-in .google:before, .primary-provider .sign-in-options .partner-sign-in .ibs:before, .primary-provider .sign-in-options .partner-sign-in .libraccio:before {
    margin-right: 22px;
    width: 20px;
    height: 20px;
}

.sign-in-options .partner-sign-in .facebook:before {
    background: url('../img/facebook_blue.png');
    background: url('../img/facebook_blue.svg'),linear-gradient(transparent,transparent);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

a.kobo-link.partner-option:hover {
    border: 1px solid #989898 !important;
}

.sign-in-options .partner-sign-in .google:before {
    background: url('../img/google.png');
    background: url('../img/google.svg'),linear-gradient(transparent,transparent);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.sign-in-options .partner-sign-in .partner-option:before {
    content: "";
    margin-right: 20px;
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
}

h5.h-right a {
    text-transform: capitalize;
    color: #000;
    text-decoration: underline;
    font-weight: 400;
    font-size: 14px;
}

h1.title.title-short {
    font-size: 2.2rem;
    font-family: "Rakuten Sans UI","Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    color: #000;
    margin: 0 0 20px 0;
    font-weight: 400;
    text-transform: capitalize;
}

h5.h-right a:hover {
    text-decoration: none;
}

.main-login-area {
    background: #fff;
    min-height: 400px;
    border: 1px solid #ccc;
    padding: 1% 3%;
}
.nav > li > a.loginMenu:hover, .nav > li > a.loginMenu:focus{
    background: #ff9500;
}

section.row.hrsec {
    margin: 0;
}
.TGAux {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    min-height: 50px !important;
    font-size: 24px !important;
}
.form-control {
    border: none;
    box-shadow: none;
    background: #f5f5f5;
    border: 0px solid #aaa;
}
section.section-breadcu {
    padding-bottom: 10px;
    padding-top: 90px;
    background: #f7f7f7 none repeat scroll 0 0;
    min-height: 40px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.header {
    font-size: 18px;
    font-size: 1.8rem;
    font-family: "Trebuchet MS",Arial,Helvetica,sans-serif;
    color: #0d0d0d;
    font-weight: 400;
    margin-bottom: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.review-container{
    margin-top: 25px;
}

a.purchase-action.review-now span {
    background: #000;
    padding: 10px 25px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border:2px solid transparent !important;
}

a.purchase-action.review-now span:hover {
    background: #fff;
    padding: 10px 25px;
    color: #000;
    font-weight: 700;
    border:2px solid #000 !important;
}

.rating-stats-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
}

.rating-histogram-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
    font-size: 13px;
    font-size: 1.3rem;
    font-family: "Trebuchet MS",Arial,Helvetica,sans-serif;
    color: #595959;
}

.star-label {
    text-transform: uppercase;
    padding-right: 10px;
}

.rating-histogram-wrapper .star-label {
    display: block;
    height: 18px;
}

.rating-histogram-wrapper dt {
    margin-bottom: 8px;
}

.rating-histogram-wrapper dd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-direction: row;
    -webkit-flex-direction: row;
    -moz-box-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.rating-histogram-wrapper dd, .rating-histogram-wrapper dt {
    margin-bottom: 8px;
}

dd {
    margin-left: 0;
}

.rating-histogram-wrapper .histogram-bar {
    background-color: #e6e6e6;
    width: 100%;
    position: relative;
}

.rating-histogram-wrapper .histogram-bar, .rating-histogram-wrapper .rating-count, .rating-histogram-wrapper .star-label {
    display: block;
    height: 18px;
}

.rating-histogram-wrapper .histogram-bar .histogram-bar-full {
    background-color: #000;
    width: 0%;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.rating-histogram-wrapper .histogram-bar:last-child, .rating-histogram-wrapper .rating-count:last-child, .rating-histogram-wrapper .star-label:last-child {
    margin-bottom: 0;
    width: 35px;
}

.rating-histogram-wrapper .rating-count {
    margin-left: 10px;
}
.contentm {
  height:90px;
  overflow:hidden;
  margin-bottom:10px;
}

.contentm.visible {
  height:auto;
  overflow:visible;
}
p.comment {
    line-height: 20px;
    line-height: 2rem;
    font-size: 16px;
    font-size: 1.6rem;
    margin: 10px 0;
    position: relative;
    color: #595959;
}

p.commentBy {
    line-height: 20px;
    line-height: 2rem;
    font-size: 16px;
    font-size: 1.3rem;
    margin: 10px 0;
    position: relative;
    color: #000000;
    font-weight: 600;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}

.heading {
    font-size: 18px;
    font-size: 1.5rem;
    font-family: "Trebuchet MS",Arial,Helvetica,sans-serif;
    color: #0d0d0d;
    font-weight: 700;
}

span.ex-related .product-list-price {
    display: inline-block !important;
}

.ex-related .iNYVzd {
    cursor: pointer;
    float: left;
    margin-right: 5px;
}

span.fillStar {
    color: #000000 !important;
    font-weight: 900;
}

.header2 {
    font-size: 1.5rem;
    font-family: "Trebuchet MS",Arial,Helvetica,sans-serif;
    color: #0d0d0d;
    font-weight: 800;
    margin: 0;
    padding: 0 10px;
}

h3.hr {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
}

.mar-50{
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 20px;
    margin: 0px;
    padding-bottom: 20px;
}

.slick-prev:before, .slick-next:before{
    color: #2c96ea !important;
    font-size: 35px !important;
}
.slick-prev {
    left: 0 !important;
}

.slick-next {
    right: 15px !important;
}

.slick-list {
    margin: 0 5% !important;
}

.product-list-vertical li a img.product-list-primary-img {
    opacity: 1;
    margin: 0 auto;
}

#more {
    display: none;
}

section.section-description, section.section-similar {
    min-height: 200px;
}

.rat-extra {
    justify-content: flex-start !important;
    margin-top: 0;
}

.pointer{
    cursor: pointer;
}

.bg-white {
    background: #fff;
}

.breadcrumb-item {
    display: inline-block;
    text-indent: 0;
    padding: 0 2px;
}

.breadcrumb-link {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    padding: 0 2px;
}

.breadcrumb-item::after {
    content: " / ";
}

.breadcrumb-item:last-child::after {
    content: " ";
}

.padding-large {
    padding: 0 7%;
}

.py-5 {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
}

.product-type-icon-container {
    position: relative;
}

.main-product-image .item-image img {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    border: 1px solid #d8d8d8;
    border-bottom: 0;
    min-width: 10rem;
    width: 100%;
}

.item-image img {
    -webkit-transition: filter .2s;
    transition: filter .2s;
    -webkit-transition-property: -webkit-filter;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #d8d8d8;
}

.product-type-icon {
    -webkit-transition: filter .2s;
    transition: filter .2s;
    -webkit-transition-property: -webkit-filter;
    display: none;
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.main-product-image .item-image:after {
    content: "";
    display: block;
    padding-bottom: 14.29%;
    /*background-image: url('../img/book_shadow.png');*/
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
}

.item-info {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.item-info .title {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-weight: 700;
    color: #000;
}

.item-info .subtitle {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-top: 0;
    padding-top: 0;
    color: #595959;
    display: inline-block;
}

.item-info .subtitle {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 2.2rem;
    line-height: 2.8rem;
    margin-top: 0;
    padding-top: 0;
    color: #595959;
    display: inline-block;
}

.item-info .abouts {
    margin: 2rem 0 1.5rem;
}

.item-info .author, .item-info .series {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 1.6rem;
    line-height: 2.2rem;
    display: block;
    padding: 0;
    margin: 0;
}

.mobile-library-tag {
    display: none;
}

.item-info .author a, .item-info .series a {
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
}

.item-info h2 {
    padding-top: 0;
}

.item-synopsis .synopsis-title {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-style: normal;
    font-size: 1.8rem;
    color: #000;
    padding: 0;
    margin: 0 0 2rem;
}

.item-synopsis {
    border-top: 1px solid #e6e6e6;
    padding-top: 2rem;
}

.pricing-details .box:first-child {
    margin-top: 0;
}

.pricing-details .box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 7px #e6e6e6;
    box-shadow: 0 0 7px #e6e6e6;
    padding: 2rem 1.5rem;
    width: 22rem;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    margin: 3rem 0;
    margin-top: 0;
}

.pricing-details .pricing-title {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-style: normal;
    color: #0d0d0d;
    padding: 0;
    margin: 0 0 20px;
}

.skeleton-shimmer {
    animation: placeHolderShimmer 2s linear infinite forwards;
    -webkit-animation: placeHolderShimmer 2s linear infinite forwards;
    background: #ebebeb no-repeat;
    background-image: linear-gradient(to right,#ebebeb 0,#d6d6d6 50%,#ebebeb 100%);
    background-size: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

.pricing-details .skeleton {
    display: block;
    height: 40px;
}

.pricing-details .skeleton div.white-line {
    top: 16px;
    position: relative;
    height: 8px;
    background-color: #fff;
}

.pricing-details .pricing-figures, .pricing-details.pricing-details-magazine {
    font-family: "Trebuchet MS",Trebuchet,Arial,Helvetica,sans-serif;
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 3rem;
}

.pricing-details .pricing-figures .original-price, .pricing-details.pricing-details-magazine .original-price {
    color: #000;
}

.pricing-details .pricing-figures .price-wrapper, .pricing-details.pricing-details-magazine .price-wrapper {
    float: right;
}

.pricing-details .pricing-figures .original-price .price, .pricing-details.pricing-details-magazine .original-price .price {
    font-size: 1.4rem;
    text-decoration: line-through;
}

.pricing-details .pricing-figures .active-price, .pricing-details.pricing-details-magazine .active-price {
    font-weight: 700;
}

.pricing-details .action-container {
    margin-top: 15px;
}

.pricing-details .pricing-figures .price, .pricing-details.pricing-details-magazine .price {
    font-size: 1.8rem;
}

.pricing-details .purchase-action:hover{
    color: #fff;
    background-color: #595959;
    text-decoration: none;
}

.pricing-details .purchase-action{
    box-sizing: border-box;
    font-family: "Trebuchet MS", Trebuchet, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 1.4rem;
    text-align: center;
    min-height: 35px;
    min-width: 70px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 700;
    display: inherit;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
    text-decoration: none;
    background: rgb(89, 89, 89);
    border-width: 3px;
    border-style: solid;
    border-radius: 0px;
    padding: 5px 7px;
    outline: 0px;
    border-color: rgb(89, 89, 89);
}

.pricing-details .purchase-action{
    display: block;
    margin: 1rem 0;
    width: 100%;
}
/* ===== 1. Header Style  ====*/
.navbar a.navbar-brand {
    color: #121212;
    font-size: 25px;
    font-weight: 700;
    height: auto;
    margin-top: 0;
    padding: 0;
    text-transform: uppercase;
}
#myNavbar .nav.navbar-nav {
    text-align: right;
}
.navbar-nav li > a {
    color: #121212;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 10px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
}
.navbar-nav li.active > a::after,.navbar-nav li.active > a::before,.navbar-nav li:hover > a::after,.navbar-nav li:hover > a::before{
    opacity:1;
}
.navbar-nav li.active > a::after,.navbar-nav li:hover > a::after{
    left:4px;
}
.navbar-nav li.active > a::before,.navbar-nav li:hover > a::before{
    right:4px;
}
.navbar-nav li {
    display: inline-block;
    float: none;
    margin: 0 3px;
}
.navbar-collapse {
    overflow-x: hidden;
}
/* ===== 2. slider style  ====*/
.swiper-slide h1 {
    color: #fff;
    font-size: 160px;
    text-transform: lowercase;
}
.home h2 {
    margin: 25px 0 47px;
    padding-left: 0;
    position: relative;
    font-size: 30px;
}
.swiper-slide h1 {
    color: #121212;
    font-size: 91px;
    text-transform: capitalize;
}
.home h2::after {
    background: #ff9500 none repeat scroll 0 0;
    bottom: -15px;
    content: "";
    height: 0;
    left: 41px;
    position: absolute;
    transform: translateX(-50%);
    width: 80px;
}
.home-buttons {
    margin: -1px 0;
    position: relative;
    text-align: left;
}
.home.image-slider .swiper-slide {
    background-position: center center !important;
    background-size: cover !important;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 31vh 255px 0;
    text-align: left;
}
.home {
    min-height: 80vh;
}
.home.image-slider .home-slider {
    min-height: 80vh;
}
.hglight {
    color: #ff9500;
}
.home-slider-title-main {
    color: #333;
    font-weight: 500;
    max-width: 754px;
    text-transform: capitalize;
}
.btn-lg, .btn-group-lg > .btn {
    border-radius: 0;
}
.swiper-wrapper .btn-primary {
    background-color: #ff9500;
    border: 1px solid transparent;
    color: #fff;
    border-radius: 5px;
}
.swiper-wrapper .btn-primary:hover {
    background-color: transparent;
    border: 1px solid #ff9500 !important;
    color: #ff9500;
}
.section-min.section.product {
    background: #fff none repeat scroll 0 0;
}
.product-list-sliderd {
    display: block;
    margin: 0 -15px;
    overflow: hidden;
}
.swiper-wrappers li.swiper-slidess {
    float: left;
    padding: 0 15px;
    width: 25%;
}
/* ===== 3. catalog style  ====*/
.catalog-area {
    background: #fff none repeat scroll 0 0;
    clear: both;
    display: block;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 90px;
    position: relative;
    z-index: 1;
}
.effect-add {
    overflow: hidden;
    position: relative;
}
.effect-add::before {
    border: 1px solid #ff9500;
    bottom: 10px;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
    content: "";
    left: 10px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 10px;
    transform: scale3d(1.4, 1.4, 1);
    transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
}
.effect-add:hover::before {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
.section-heading::after {
    height: 2px;
}

/* ===== 4. product style  ====*/
.product-list-vertical .product-list-right {
    text-align: left;
    width: 100%;
}
.product-list-vertical li,.product-list-slider {
    padding: 0;
}
.product-list-right {
    padding: 8px 0;
}
.product-list-vertical .btn {
    border: 0 none;
    display: inline-block;
    float: none;
    margin: 0 !important;
    padding: 0;
    width: auto;
}
.product-list li {
    margin-bottom: 51px;
    text-align: left;
}
.product-list-vertical li a {
    color: #ff9500;
    position: relative;
}
.product-list-vertical .add-item {
    border: 0 none;
    color: #121212;
    display: inline-block;
    float: left;
    font-weight: 700;
    margin: 0 !important;
    padding: 0;
    width: 100%;
    transform: scaleY(1);
    transition: all 0.4s ease 0s;
}
.product-list li:hover  .add-item {
    transform: scaleY(1);
}
.product-list li span {
    display: block;
    text-transform: capitalize;
    font-weight: 400;
}
.new-products-area .left-arrow-negative {
    left: 0;
}
.new-products-area  .right-arrow-negative {
    right: 0;
}
.product {
    padding-bottom: 134px;
}
.rating-area {
    display: flex !important;
    margin-top: 12px;
}
.rating-area span {
    margin: 0 2px;
    color: #aaa;
}
.rating-area span.high_light {
    color: #ff9500;
}
.p-badd {
    background: #fff none repeat scroll 0 0;
    clear: both;
    display: block;
    overflow: hidden;
    padding: 60px 0;
    position: relative;
}

section.hrsec{
    min-height: 100px;
}

.product-list-sliderd {
    margin-bottom: 0px;
}
.single-category-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222222;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: .45;
    z-index: 2;
}
.single-category-box:hover img, .single-category-box:focus img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.single-category-box img {
    width: 100%;
}
.single-category-box .category-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;
}
.single-category-box img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.single-category-box .category-content h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
}
.single-category-box .link-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}
.single-category-box {
    margin-bottom: 85px;
    position: relative;
    text-align: center;
    overflow: hidden;
    clear: both;
}
.about-counter .icon {
    font-size: 36px;
    color: #121212;
    border: 1px solid #F5F5F5;
    width: 100px;
    height: 100px;
    line-height: 86px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: block;
    margin: 0 auto;
}
.about-counter .icon::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #F5F5F5;
    border-radius: 50%;
    margin: 6px;
    z-index: -1;
}
.about-counter-single .counter {
    padding: 0;
}
.about-counter-single .counter:hover .icon, .about-counter-single .counter:focus .icon {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    color:#ff9500;
}

.about-counter-single .counter .icon  {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

/* ===== 5. about style  ====*/
.about {
    background: #fff no-repeat scroll center center / cover;
    min-height: inherit;
    padding: 50px 0 140px;
    position: relative;
    background-attachment: fixed;
    text-align: center;
}
.about::after {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
}
.about .container {
    position: relative;
    z-index: 99;
}
.about span {
    color: #ff9500;
}
.about h4 {
    font-size: 20px;
    margin-bottom: 14px;
    padding-top: 8vh;
    text-transform: capitalize;
}
.styled-list li::before {
    display: none;
}
.styled-list li {
    list-style: inside none square;
    margin-bottom: 9.5px;
    padding-left: 0;
    position: relative;
}
.about h2 {
    margin-top: 0;
}
.read-more-btn > a {
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    margin-top: 9px;
    padding: 12px 33px;
    text-transform: uppercase;
}
.read-more-btn > a {
    background: #ff9500 none repeat scroll 0 0;
    border: 1px solid #ff9500;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-top: 9px;
    padding: 11px 33px;
    text-transform: uppercase;
}
.read-more-btn > a:hover {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #ff9500 !important;
}
.about p {
    font-size: 16px;
    margin-bottom: 15px;
    max-width: 89%;
}
.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 5px;
    position: relative;
    z-index: 9;
}
.product .product-list-pagination.text-center.swiper-pagination-clickable.swiper-pagination-bullets {
    z-index: 0;
}
.countdown-price {
    display: block;
    float: left;
    margin-bottom: 30px;
    margin-top: 0;
    text-align: left;
    width: 100%;
}
.countdown-container {
    display: block;
    float: left;
    padding: 71px 30px 30px 0;
}
.countdown-container p {
    color: #777;
    margin: 0 0 30px;
}
.countdown-price del {
    color: #aaa;
    font-size: 18px;
    font-weight: normal;
    margin-left: 9px;
}
.countdown-container .btn.btn-default.add-item, .btn.btn-default.btn-lg, span.product-right-section .btn.btn-default.add-item, .contact-form button.btn {
    background: #ff9500 none repeat scroll 0 0;
    border: 1px solid #ff9500;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    padding: 12px 28px;
    transition: all 0.3s ease 0s;
}
.countdown-container .btn.btn-default.add-item:hover ,.btn.btn-default.btn-lg:hover,span.product-right-section .btn.btn-default.add-item:hover,.contact-form button.btn:hover{
    background: transparent;
    border: 1px solid #ff9500;
    color: #ff9500;
}
.countdown {
    padding-bottom: 58px;
}
.cart-items-counter .animate {
    color: #fff;
}
.custom-radio {
    padding-left: 0;
}
.cart-empty .icon {
    color: #ff9500;
    display: block;
    font-size: 80px;
    margin-top: 17px;
}
.about-counter .container-fluid {
    padding: 0;
}
.about-counter-single {
    border-right: 0 none;
}
.about-counter {
    background: #fff none repeat scroll 0 0;
    padding: 0;
    padding-bottom: 5px;
}
.about-counter-single {
    border-right: 0 none;
    padding: 59px 0 46px;
}
.cnt1.about-counter-single {
    background: #f3f3f3 none repeat scroll 0 0;
}
.cnt2.about-counter-single {
    background: #e8e8e8 none repeat scroll 0 0;
}
.cnt3.about-counter-single {
    background: #f3f3f3 none repeat scroll 0 0;
}
.cnt4.about-counter-single {
    background: #e8e8e8 none repeat scroll 0 0;
}

/* ===== 6. testimonials style  ====*/
.testimonials {
    background: #fff;
    position: relative;
}
.section-heading {
    margin: 45px 0 74px;
    position: relative;
    text-align: center;
    z-index: 9;
}
.add-area {
    position: relative;
}
.add-area h2 {
    background: rgba(0, 203, 153, 0.67) none repeat scroll 0 0;
    border-radius: 5px 0 0 5px;
    border-right: 25px none;
    font-size: 15px;
    left: auto;
    margin-left: -75px;
    margin-top: -18.5px;
    opacity: 0;
    padding: 11px 20px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 73%;
    transition: all 0.3s ease 0s;
    width: 150px;
}
.add-area:hover .effect-add > h2 {
    color: #fff;
    opacity: 1;
    right: 0;
}
.testimonials-slider .shadow {
    box-shadow: 0 0px 9px 0 rgba(0, 0, 0, 0.05);
}
.add-area .effect-add > h2:hover {
    background:#ff9500;
    color: #fff;
}
.cart-widget-container .section-heading::after,.product-description .section-heading::after {
    display: none;
}
.nk.section-heading::after,.nk.section-heading::before {
    display: none;
}
/* ===== 7. contact style  ====*/
.contact-with-map {
    background: #fff none repeat scroll 0 0;
    padding-bottom: 0;
    padding-top: 89px;
}
.contact-data ul {
    background: transparent none repeat scroll 0 0;
    float: left;
    margin-bottom: 60px;
    width: 100%;
}

/* ===== 7. footer style  ====*/
.footer-logo {
    height: auto;
    margin: 30px 0;
    text-transform: lowercase;
    width: 100%;
}
.footer-newsletter .btn.btn-default {
    background: #ff9500 none repeat scroll 0 0;
    border: 1px solid #ff9500;
    border-radius: 0;
    color: #fff;
    font-weight: 700;
    padding: 12px 28px;
    transition: all 0.3s ease 0s;
}
.footer-newsletter .btn.btn-default:hover{
    background: transparent;
    border: 1px solid #ff9500;
    color: #ff9500;
}
.footer-newsletter .form-control {
    height: 47px;
}
.form-control:focus {
    border-color: #ff9500;
}
.msuccess {
    padding: 5px 2%;
    border: 1px solid #008d00;
    color: #008d00;
}
.merror {
    padding: 5px 2%;
    border: 1px solid #ff1c0b;
    color: #ff1c0b;
}
.mb-10{
    margin-bottom: 20px;
}
.btn-hover-border-primary {
    background: #000;
    color: #fff !important;
    font-weight: 600;
    border-radius: 0;
}
.btn-hover-border-primary:hover {
    background: #fff;
    color: #000 !important;
    font-weight: 600;
    border-radius: 0;
    border: 2px solid #000;
}
.footer {
    float: left;
    height: auto;
    min-height: auto;
    padding-bottom: 15px;
    padding-top: 81px;
    position: relative;
    width: 100%;
}
.form-control {
    padding-left: 10px;
    color: #000;
    font-weight: 400;
}
.contact-data .icon {
    color: #ff9500;
    display: inline-block;
    float: left;
    font-size: 22px;
    height: 43px;
    line-height: 11px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    top: 5px;
    width: 23px;
}
.contact-data > h4 {
    margin-bottom: 21px;
}
.contact-data ul {
    background: transparent none repeat scroll 0 0;
    float: left;
    margin-bottom: 36px;
    width: 100%;
}
.copy-right {
    margin-top: 60px;
}
.copy-right > p {
    margin: 0;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: #ff9500;
}
a {
    color: #ff9500;
    text-decoration: none;
}
.product-right-section {
    display: block;
    float: left;
    margin-top: 42px;
    text-align: right;
}

a#scrollUp {
    background: #ff9500 none repeat scroll 0 0;
    bottom: 94px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 42px;
    position: absolute;
    right: 32px;
    text-align: center;
    width: 40px;
    border:1px solid #ff9500 !important;
    transition: all 0.4s ease 0s;
}
a#scrollUp:hover {
    background: transparent;
    color: #ff9500;
}
.about-counter-single {
    border-bottom: 0 none;
}
.navbar-nav li > a {
    border-bottom: 0 none;
}
.product-right-section > span {
    font-weight: bold;
}
/* ===== 8. Preloader style  ====*/
.page-loader {
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9998;
}
.loader {
    background: #ff9500;
    position: absolute;
    display: inline-block;
    height: 40px;
    width: 40px;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    text-indent: -9999em;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite;
}

@-webkit-keyframes ball-scale {

    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

}

@keyframes ball-scale {

    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

}

/*home 2 style*/
.hm-2.image-slider {
    border-bottom: 1px solid #f8f8f8;
}
.product-list-price {
    display: inline-block;
    margin-right: 8px;
}
.product-list-price.sell-p > del {
    color: #aaa;
    font-size: 14px;
}
.product-list li .sale-p.new-p {
    background: #8bd485 none repeat scroll 0 0;
}
.product-list li, .product-list-vertical .product-list-right {
    text-align: left;
}
.product-list li span {
    display: block;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    margin: 0 auto;
    width: 100%;
}
.product-list li span.sale-p {
    background: #EC5051 none repeat scroll 0 0;
    border-radius: 0;
    color: #fff;
    font-size: 13px;
    left: 16px;
    letter-spacing: 2px;
    line-height: 25px;
    padding: 2px 13px;
    position: absolute;
    text-transform: uppercase;
    top: 19px;
    /* transform: rotate(90deg); */
    border-radius: 5px;
    z-index: 99;
}
#accordion2 .card {
    background: #fbfbfb;
    margin: 5px 0;
}
audio.d-none {
    display: none;
}
a.playAudio i {
    font-size: 24px;
    margin-top: 0px;
}
.text-dark {
    color: #000 !important;
}
.text-capitalize {
    text-transform: capitalize;
}
.mt-4{
    margin-top: 2rem;
}
.font-600{
    font-weight: 600;
}
.more-important {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 2rem;
}
#accordion2 .card-header {
    background: #fff;
    padding: 1rem;
    margin-bottom: 5px;
}
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: 0 !important;
    outline-offset: -2px;
}
#accordion2 .btn{
    padding: 0;
    white-space: normal;
}
.accordion .card-header h2{
    padding: 0rem;
    margin: 0;
    font-size: 15px;
}
.accordion .card-header button {
    padding: 0;
}
#accordion2 .card-body {
    padding: 1rem;
}
.product-list-name.h4.black-color:hover {
    color: #ff9500;
}
.product-list-name.h4.black-color{
    transition: all 0.3s ease 0s;
}
.product-list-vertical .add-item:hover {
    color: #000;
}
.product .product-list-price {
    display: block;
    font-size: 17px;
}
.counter > h2 {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0px;
}
.cnt2 .counter > h2,.cnt4 .counter > h2 {
    margin-top: 18px;
}
.navbar-nav li.active > a, .navbar-nav li:hover > a {
    color: #ff9500;
}
.new-products-area .section-heading {
    margin-bottom: 42px;
}
.product-list-vertical .add-item:hover{
    color:#ff9500;
}
.product-list-name {
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
}
.product-tabs .section-heading::before,.modal-body .section-heading::before  {
    display: none;
}
.product-tabs .section-heading {
    margin: 15px 0 7px;
    text-align: left;
}
.product-tabs .tab-pane {
    padding-left: 0;
    padding-right: 0;
}
p {
    line-height: 30px;
}
.modal-body .section-heading {
    margin: 0 0 17px;
    text-align: left;
}
.modal-body .pull-left.nk.section-heading {
    font-size: 18px;
    margin-top: 14px;
}
.modal-body  .product-right-section {
    display: block;
    float: left;
    margin-top: 0;
    text-align: right;
}
.modal-body  .product-description {
    float: left;
    margin-bottom: 45px;
    margin-top: 30px;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border: 1px solid #ff9500;
}
.product-modal .nav.nav-tabs {
    margin-bottom: 25px;
}
footer {
    background: #f7f7f7 none repeat scroll 0 0;
    border-top: 1px solid #f0f0f0;
    padding: 73px 0 24px;
    position: relative;
}
footer .col-md-3 .fa {
    font-size: 42px;
}
footer a {
    color: #555;
}
footer hr {
    border-color: #ffffff;
    margin-bottom: 22px;
    margin-top: 70px;
}
footer .footer-copyright {
    padding-top: 5px;
}
.social-icon {
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
}
.social-icon li {
    display: inline-block;
    list-style: outside none none;
}
.social-icon li a {
    color: #777;
    cursor: pointer;
    font-size: 16px;
    margin: 22px 12px 10px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.4s ease-in-out 0s;
    vertical-align: middle;
}
.social-icon li a:hover {
    color: #000;
    transform: scale(1.1);
}
a#scrollUp {
    background: #ff9500 none repeat scroll 0 0;
    border-radius: 5px;
    bottom: 95px;
    color: #fff;
    font-size: 23px;
    height: 40px;
    line-height: 41px;
    position: absolute;
    right: 32px;
    text-align: center;
    width: 40px;
}
.social {
    height: auto;
    min-height: auto;
    width: auto;
}
.social a {
    border-radius: 5px;
    color: #555;
    display: inline-block;
    font-size: 14px;
    margin-right: 15px;
    padding: 7px 0 5px;
    transition: all 300ms ease-in-out 0s;
}
.social a:hover {
    color: #ff9500;
}
.footer-copyright > p {
    font-size: 22px;
}
.info_menu {
    padding-left: 50px;
}
.info_menu > ul {
    display: inline-block;
    float: left;
    margin-right: 21px;
}
.info_menu li {
    margin-bottom: 12px;
}
.footer h4 {
    margin-bottom: 15px;
    margin-top: 4px;
}
.footer-newsletter {
    margin-top: 0;
}
.social {
    float: left;
    margin-top: 20px;
}
.fndus {
    font-size: 13px;
    font-weight: bold;
    margin-right: 9px;
    margin-top: 17px;
    text-transform: uppercase;
}
a:hover, a:focus {
    color: #ff9500 ;
}
.footer-copyright > p {
    font-size: 17px;
    text-align: center;
}
.navbar-header .navbar-toggle .icon-bar {
    width: 30px;
}
.navbar-header .navbar-toggle {
    margin-top: 27px;
}
.btn:hover, .btn:focus, .btn.focus {
    outline: none;
}
.f_logo img {
    margin-bottom: 20px;
    width: 65%;
}
.new-products-area {
    clear: both;
    display: block;
    overflow: hidden;
    padding-top: 6px;
}
.section-min.section.product {
    padding-bottom: 7px;
}
.section-heading::before {
    display: none;
}
.cart-widget .section-heading {
    margin-bottom: 14px;
    margin-top: 11px;
}
.cart-widget-container .close {
    background: transparent none repeat scroll 0 0 !important;
}
.cart-delivery {
    margin-top: 11px;
}
#testimonials.testimonials {
    padding: 0 0 130px;
}
.contact-with-map {
    padding-top: 21px;
}
.contact-form {
    margin-bottom: 67px;
}
.fndus {
    margin-top: 6px;
}
button.close, .close {
    background: transparent none repeat scroll 0 0;
    top: -3px;
}
.data-effect .data-effect-item {
    position: relative;
    overflow: hidden;
}
.flat-image-box.style-1 .elm-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}
.overlay-effect.bg-color-1 {
    background-color: rgba(255,255,255,0.2);
}
.overlay-effect {
    position: absolute;
    width: 100%;
    height: 100%;
}
.flat-image-box.style-1 .elm-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}
#special {
    background-attachment: fixed;
}

@-webkit-keyframes effect_shine {
    100% {
        left: 150%;
    }
}

@keyframes effect_shine {
    100% {
        left: 150%;
    }
}
.banner-box{
    position: relative;
}
.banner-box .banner-inner {
    overflow: hidden;
    position: relative;
}
.banner-box .banner-image {
    width: 100%;
    height: 100%;
}

.banner-box .banner-image img {
    width: 100%;
}

.banner-box .banner-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1;
    -webkit-transition: all 250ms ease-out;
    -moz-transition: all 250ms ease-out;
    -ms-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
}

.banner-box .banner-link {
    display: inline-block;
    text-indent: -9999px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: inherit;
    opacity: 0;
    transition: all 250ms ease-out;
    z-index: 2;
}

.banner-box .banner-title-1 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 0;
    letter-spacing: 10px;
}

.banner-box .banner-title-4 {
    font-weight: bold;
    letter-spacing: 4px;
    border: 3px solid #fff;
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.banner-box .banner-title-4 span {
    width: 100%;
    padding: 15px 50px;
    float: left;
    text-transform: uppercase;
}
.banner-box .banner-title-4 span:first-child {
    background: transparent;
    color: #282828;
}
.banner-box .banner-title-4 span:last-child {
    color: #fff;
}
.banner-box .banner-title-5 {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 8px;
    text-transform: capitalize;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
}
.banner-box .banner-title-6 {
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 700;
    color: #ffffff;
}
.heading-bigger {
    color: #cf987e;
    font-size: 100px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0;
    color: #ffffff !important;
}
.banner-box.banner-hover-3 .banner-image {
    overflow: hidden;
    position: relative;
}
.banner-box.banner-hover-3 .banner-image:before{
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    -moz-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    transform: skewX(-25deg);
}
.banner-box.banner-hover-3:hover .banner-image:before{
    -webkit-animation: effect_shine 1s;
    -moz-animation: effect_shine 1s;
    -ms-animation: effect_shine 1s;
    -o-animation: effect_shine 1s;
    animation: effect_shine 1s;
}
.banner-box.banner-hover-3 .banner-image img {
    -webkit-transition: opacity 0.35s, transform 0.35s;
    -moz-transition: opacity 0.35s, transform 0.35s;
    -ms-transition: opacity 0.35s, transform 0.35s;
    -o-transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
    transform: scale(1.02);
}

.banner-box.banner-hover-3:hover .banner-image img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.banner-box.banner-hover-3:hover .banner-btn-3 {
    color: #fff !important;
    border-color: #cf987e;
}

.banner-box.banner-hover-3:hover .btn-style-1 {
    color: #fff !important;
    border-color: #cf987e;
}

.banner-box.banner-hover-3:hover .banner-btn-4 {
    background-color: #282828;
}


.banner-box.banner-type-5 .banner-info {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.banner-box.banner-type-5 .banner-info--inner {
    text-align: center;
}
.banner-box.banner-type-5 .btn.btn-style-1 {
    min-width: 160px;
    min-height: 50px;
    line-height: 50px;
    cursor: pointer;
    background: transparent;
    color: #ffffff;
    border: 1px solid #fff;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    padding: 0 20px;
    text-transform: uppercase;
    font-family: inherit;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    margin-top: 30px;
    border-radius: 5px;
}
span.product-list-left.pull-left {
    margin: 0 auto;
    width: 100%;
}
.actives {
    background: #2d97ea !important;
    color: #fff !important;
}
.banner-box.banner-hover-3:hover .btn-style-1,
.banner-box.banner-type-5 .btn.btn-style-1:hover{
    background-color: #ff9500;
    color: #fff;
    border-color: #ff9500;
}

.swiper-wrappers li.swiper-slidess {
    text-align: center;
}
.product-list li, .product-list-vertical .product-list-right {
    text-align: center !important;
}
@media (max-width: 1499px){
    .banner-box.banner-type-5 .banner-info .banner-title-4 {
        font-size: 30px;
        letter-spacing: 5px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-4 span {
        padding: 15px 18px 15px 17px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-1 {
        letter-spacing: 1px;
    }
    .banner-box.banner-type-5 .banner-info .heading-bigger {
        font-size: 70px;
    }
}


@media (max-width: 1199px){
    .banner-box.banner-type-5 .btn.btn-style-1{
        margin-top: 10px;
    }
}

@media (max-width: 991px){
    .banner-box.banner-type-5 .banner-info .heading-bigger {
        font-size: 45px;
    }
    .mt-xs-5 {
        margin-top: 85px;
    }
    .rating-stats-container {
        box-sizing: border-box;
        width: 100%;
        margin: 25px 0;
        border-right: 0px solid #d9d9d9;
        border-left: 0px solid #d9d9d9;
    }

    .review-container {
        margin-top: 25px;
        margin-bottom: 31px;
    }
}

@media (max-width: 575px){
    .header2 {
        font-size: 1.5rem;
    }
    .main-product-image .item-image img {
        box-sizing: border-box;
        display: block;
        border: 1px solid #d8d8d8;
        border-bottom: 0;
        min-width: 10rem;
        width: 50%;
        margin: 0 auto;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-5 {
        letter-spacing: 0px;
        margin-bottom: 10px;
    }

    .rating-stats-container {
        box-sizing: border-box;
        width: 100%;
        margin: 25px 0;
        border-right: 0px solid #d9d9d9;
        border-left: 0px solid #d9d9d9;
    }

    .review-container {
        margin-top: 25px;
        margin-bottom: 31px;
    }

    .pricing-details .box {
        width: auto;
        margin: 0rem 0;
    }

    .pricing-details {
        margin-top: 20px;
    }
}
.mm_b_30 {
    margin-bottom: 20px;
}
.banner-box.banner-type-5.banner-hover-3::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    content: "";
    opacity: 0.3;
}
.banner-area {
    position: relative;
    overflow: hidden;
    display: block;
    clear: both;
    background: #fff;
    padding-top: 140px;
}
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-top: -5px;
}
.fndus {
    margin-top: 19px;
}
section.section-min.nnrr {
    min-height: auto;
    padding-bottom: 7px;
    background: #fff;
    padding-top: 80px;
}
.single-category-box .category-content h3 {
    margin: 0;
}

/* Contact-message-modal  css start */
.cr-contact-message-modal {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
    background: rgba(25, 25, 25, 0.95);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    visibility: hidden;
    opacity: 1;
    z-index: -9;
    -webkit-transition: 0.5s ease-out 0s;
    -moz-transition: 0.5s ease-out 0s;
    -ms-transition: 0.5s ease-out 0s;
    -o-transition: 0.5s ease-out 0s;
    transition: 0.5s ease-out 0s;
    padding: 30px;
    padding-right: 60px;
    -webkit-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
}
.cr-contact-message-modal .form-messege {
    font-size: 20px;
    margin-bottom: 0;
    line-height: 1.3;
}
.cr-contact-message-modal .form-messege.error {
    color: #f6644F;
}
.cr-contact-message-modal .form-messege.success {
    color: #47BB8F;
}
.cr-contact-message-modal.is-visible {
    opacity: 1;
    visibility: visible;
    z-index: 99999;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.cr-contact-message-modal button {
    position: absolute;
    right: 25px;
    top: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    background: transparent;
    color: #ffffff;
    outline: none;
    border: none;
    font-size: 20px;
}

@media only screen and (max-width: 767px) {
    .cr-contact-message-modal {
        padding-right: 40px;
    }
    .cr-contact-message-modal button {
        right: 10px;
        top: 10px;
    }
}

/* index-3 style */
.index-3 .home.image-slider .swiper-slide {
    min-height: 100vh;
    padding: 35vh 92px 0;
}
.index-3  .swiper-slide h1 {
    font-size: 60px;
}
.index-3 .home h2 {
    font-size: 30px;
}
.index-3  .home .left-arrow, .index-3  .home .right-arrow,.index-3  .home .left-arrow-negative, .index-3 .home .right-arrow-negative {
    display: none;
}
.h3_banner .banner-box.banner-type-5 .banner-info--inner.one_inr {
    top: -25%;
}
.h3_banner .banner-box.banner-type-5 .banner-info--inner {
    position: relative;
}
.banner-info--inner.two_inr {
    right: -30%;
    text-align: right !important;
}
.h3_banner  .banner-box.banner-type-5.banner-hover-3::after {
    opacity: 0.2;
}
.banner-info--inner.three_inr {
    left: -32%;
    text-align: left !important;
}
.index-3 #testimonials.testimonials {
    padding: 0 0;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    padding-top: 42px;
    padding-bottom: 80px;
}
.single-category-box2 img {
    width: 100%;
}
.index-3 .section-min.section.product {
    padding-bottom: 57px;
    padding-top: 70px;
}
.single-category-box2 {
    clear: both;
    overflow: hidden;
    padding-bottom: 83px;
}

footer hr {
    border-color: #f9f9f9;
}
.blog_details a h1 {
    font-size: 20px;
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    color: #000;
    line-height: 29px;
}
.b_red_more {
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
    text-transform: capitalize;
}
.single_blog img {
    width: 100%;
}
.blog_area .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.blog_area  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.blog_area {
    clear: both;
    overflow: hidden;
    background: #f9f9f9;
    padding: 90px 0 120px;
}
.index-3  .about-counter {
    padding-bottom: 75px;
}
.index-3   .contact-with-map {
    padding-top: 90px;
}








/* large desktop :1366px. */
@media (min-width: 1200px) and (max-width: 1600px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .index-3 .home.image-slider .swiper-slide {
        min-height: 100%;
        padding: 0 25px;
        padding-bottom: 140px;
        padding-top: 160px;
    }
    .index-3 .swiper-slide h1 {
        font-size: 45px;
    }
    .index-3  .home.image-slider .home-slider {
        min-height: 100%;
    }
    .index-3  .home {
        min-height: 100%;
    }
    .banner-box.banner-type-5.banner-hover-3.nnrrnew {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .index-3  .banner-area {
        padding-top: 60px;
    }
    .index-3 .section-min.section.product {
        padding-bottom: 57px;
        padding-top: 0;
        margin-top: -10px;
    }
    .index-3  .product-list-sliderd {
        margin-bottom: 10px;
    }
    .index-3   .single-category-box2 {
        padding-bottom: 6px;
    }
    .index-3 .section-min.section.product {
        padding-bottom: 15px;
    }
    .index-3 #testimonials.testimonials {
        padding-top: 10px;
        padding-bottom: 80px;
    }
    .index-3 #testimonials.testimonials {
        padding-top: 10px;
        padding-bottom: 55px;
        min-height: auto;
    }
    .index-3  section.section-min.nnrr {
        padding-top: 0;
    }
    .index-3  .about-counter-single {
        border-right: 0 none;
        padding: 59px 0 0;
    }
    .index-3 .about-counter {
        padding-bottom: 25px;
    }
    .blog_area {
        padding: 15px 0 24px;
    }
    .single_blog {
        margin-bottom: 30px;
    }
    .index-3 .contact-with-map {
        padding-top: 10px;
    }


}

/* small mobile :320px. */
@media (max-width: 767px) {
    .index-3 .home.image-slider .swiper-slide {
        min-height: auto;
        padding: 0 15px;
        padding-bottom: 80px;
        padding-top: 0px;
    }
    .index-3 .swiper-slide h1 {
        font-size: 45px;
    }
    .banner-info--inner.two_inr {
        right: -12%;
    }
    .banner-info--inner.three_inr {
        left: -19%;
    }
    .index-3 .section-min.section.product {
        padding-top: 0;
    }
    .single-category-box2 {
        padding-bottom: 60px;
    }
    .index-3  #testimonials.testimonials {
        margin-top: -33px;
        padding: 0 0 52px;
        padding-top: 32px !important;
    }
    .index-3 .footer {
        padding-top: 60px;
    }
    .index-3  .about-counter {
        padding-bottom: 50px;
    }
    .blog_area {
        padding: 30px 0 25px;
    }
    .single_blog {
        margin-bottom: 30px;
    }
    .index-3 .contact-with-map {
        padding-top: 31px;
    }













}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {



}


#contact-map {
    width: 100%;
    height: 640px;
}
#contact-map  iframe {
    width: 100% !important;
    height: 100%;
    border: 0;
}
























