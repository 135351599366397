/* Normal desktop :992px. */
.dropdown-lgo-small{
    display: none;
}
.dropdown-lgo-large{
    display: block;
}
@media (min-width: 992px) and (max-width: 1169px) {
    .home.image-slider .swiper-slide {
        padding: 36vh 87px 0;
    }
    .extrbar.d-xs-block {
        display: block !important;
        padding-top: 85px !important;
        background: #fff;
        padding: 10px;
    }
    .dropdown-lgo-large{
        display: none;
    }
    #myNavbar .nav.navbar-nav {
        text-align: right;
        background: #fff;
        height: 100vh;
        box-shadow: 1px 2px 3px #333;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #fff;
    }
    .dropdown-lgo-small{
        display: block;
    }
    .navbar {
        border: none;
        background: transparent;
        text-align: center;
    }
    .container {
        width:100%;
        margin: 0;
        padding: 0 25px;
    }
    .headRight {
        width: 45%;
        float: right;
        text-align: right;
    }
    .headRight .navbar-nav li:first-child a {
        border-top: 0px solid #f5f5f5!important;
    }
    .headRight ul {
        float: left;
        width: 100%;
    }
    a.navbar-brand {
        width: 55%;
        float: left;
    }
    .navbar .navbar-header {
        margin-top: 17px;
        width: 100%;
    }
    .navbar-brand img {
        height: auto;
        margin-left: 0;
        margin-top: 14px;
        width: 40%;
    }
    .navbar .navbar-header {
        margin: 0;
    }
    .navbar-header .navbar-toggle {
        margin-top: 22px;
    }
    .navbar a.navbar-brand {
        margin-bottom: 24px;
        margin-top: 11px;
    }
    .home {
        min-height: 75vh;
    }
    .home.image-slider .home-slider {
        min-height: 75vh;
    }
    .counter > h2 {
        font-size: 20px;
    }
    .f_logo {
        width: 65%;
    }
    .f_logo img {
        width: 40%;
    }
    .fndus {
        margin-top: 17px;
    }
    .home.image-slider .swiper-slide {
        min-height: 100vh;
    }
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
        margin-top: 4px;
        margin-bottom: 19px;
    }
    .navbar-toggle {
        top: 15px;
    }
    .navbar-header .navbar-toggle {
        margin-top: 0;
    }
    .banner-box.banner-type-5 .banner-info .heading-bigger {
        font-size: 35px;
    }
    .banner-box .banner-title-1 {
        font-size: 14px;
    }
    .banner-box .banner-inner {
        margin-bottom: 29px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-4 {
        font-size: 15px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-5 {
        margin-bottom: 0;
    }
    .banner-box.banner-type-5 .btn.btn-style-1 {
        width: 130px;
    }
    .counter > h2 {
        font-size: 17px;
    }
    .f_logo img {
        width: 100%;
    }
    .searchBar {
        display: none;
    }

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .home.image-slider .swiper-slide {
        padding: 19vh 12vh 0;
    }
    .extrbar.d-xs-block {
        display: block !important;
        padding-top: 85px !important;
        background: #fff;
        padding: 10px;
    }
    .navbar {
        height: 50px;
        margin: 15px 0px;
    }
    .dropdown-lgo-large{
        display: none;
    }
    #myNavbar .nav.navbar-nav {
        text-align: right;
        background: #fff;
        height: 100vh;
        box-shadow: 1px 2px 3px #333;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #fff;
    }
    .dropdown-lgo-small{
        display: block;
    }
    .navbar {
        border: none;
        background: transparent;
        text-align: center;
    }
    .container {
        width:100%;
        margin: 0;
        padding: 0 25px;
    }
    .headRight {
        width: 45%;
        float: right;
        text-align: right;
    }
    .headRight .navbar-nav li:first-child a {
        border-top: 0px solid #f5f5f5!important;
    }
    .headRight ul {
        float: left;
        width: 100%;
    }
    a.navbar-brand {
        width: 55%;
        float: left;
    }
    .swiper-wrapper .btn-primary {
        width: auto;
    }
    .product-list-vertical .product-list-right {
        text-align: left;
    }
    .product-list li {
        margin-bottom: 15px;
        text-align: center;
    }

    .countdown-container {
        text-align: center;
    }
    .countdown-container .btn.btn-default.add-item, .btn.btn-default.btn-lg, span.product-right-section .btn.btn-default.add-item, .contact-form button.btn {
        width: auto;
    }
    .product-list li span {
        float: inherit !important;
    }
    .countdown-price {
        text-align: center;
    }
    .swiper-wrappers li.swiper-slidess {
        width: 50%;
    }
    .swiper-wrappers li.swiper-slidess .product-list-left {
        margin-bottom: 18px;
    }
    .contact-data {
        float: left;
        width: 50%;
    }
    .info_menu {
        display: block;
        margin: 35px 0;
        overflow: hidden;
        padding-left: 15px;
    }
    .navbar .navbar-header {
        margin-top: 17px;
        width: 15%;
    }
    .f_logo img {
        margin-bottom: 20px;
        width: 65%;
    }
    .navbar .navbar-header {
        margin-top: 0px;
        width: 100%;
    }
    .navbar-brand img {
        height: auto;
        margin-left: 0;
        margin-top: 14px;
        width: 40%;
    }
    .navbar-header .navbar-toggle {
        margin-top: 3px;
    }
    .navbar a.navbar-brand {
        margin-bottom: 22px;
        margin-top: 11px;
    }
    .home.image-slider .swiper-slide {
        min-height: auto;
        padding: 150px 20px;
        padding-bottom: 69px;
    }
    .home {
        min-height: auto;
    }
    .home.image-slider .home-slider {
        min-height:  auto;
    }
    .counter > h2 {
        font-size: 20px;
    }
    .f_logo {
        width: 65%;
    }
    .f_logo img {
        width: 40%;
    }
    .fndus {
        margin-top: 17px;
    }
    .navbar-brand img {
        height: auto;
        margin-left: 0;
        margin-top: 26px;
        width: 50%;
    }
    .banner-box.banner-type-5 .btn.btn-style-1 {
        width: 200px;
    }
    .d_none {
        display: none;
    }
    .searchBar {
        display: none;
    }









}
.headRight li {
    float: right;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .dropdown-lgo-large{
        display: none;
    }
    .extrbar.d-xs-block {
        display: block !important;
        padding-top: 75px !important;
        background: #fff;
        padding: 10px;
    }
    div#myNavbar {
        position: absolute;
        left: 0px;
        top: 55px;
    }
    .navbar {
        padding: 0px 0px;
        margin: 0;
    }
    #myNavbar .nav.navbar-nav {
        text-align: left;
        background: #fff;
        height: 100vh;
        padding: 25px;
        box-shadow: 1px 2px 3px #333;
    }
    div#myNavbar {
        width: 200px;
        float: left;
    }
    .dropdown-menu-right {
        top: 57px !important;
        position: absolute !important;
    }
    .navbar-nav .open .dropdown-menu {
        background-color: #fff;
    }
    .navbar {
        border: none;
        background: transparent;
        text-align: center;
    }
    .container {
        width:100%;
        margin: 0;
        padding: 0 25px;
    }
    .headRight {
        text-align: right;
    }
    .headRight .navbar-nav li:first-child a {
        border-top: 0px solid #f5f5f5!important;
    }
    .navbar-nav li.active > a::after, .navbar-nav li.active > a::before, .navbar-nav li:hover > a::after, .navbar-nav li:hover > a::before {
        opacity: 0;
    }
    .product-list-vertical .product-list-right {
        text-align: left;
    }
    .home.image-slider .swiper-slide {
        padding: 83px 10px;
        text-align: left;
    }
    .home-slider-title-main {
        font-size: 20px;
        padding: 0;
    }
    .swiper-slide h1 {
        font-size: 48px;
    }
    .home.image-slider .swiper-slide {
        padding: 83px 10px;
        text-align: center;
    }
    .home-buttons {
        text-align: center;
    }
    .swiper-wrapper .btn-primary {
        display: inline-block;
        width: 66%;
    }
    .home-buttons a {
        padding: 15px 30px;
    }
    .home.image-slider .swiper-slide {
        padding: 16vh 10px;
        padding-bottom: 92px;
        min-height: auto;
    }
    .home {
        min-height: auto;
    }
    .home.image-slider .home-slider {
        min-height: auto;
    }
    .navbar-nav li > a {
        padding: 12px 10px;
    }
    .banner-area {
        padding-top: 60px;
    }

    .add-area {
        margin-bottom: 20px;
    }
    .effect-add img {
        width: 100%;
    }
    .product-list li {
        margin-bottom: 15px;
        text-align: center;
    }
    .product-list li {
        margin-right: 0;
    }
    .about h4 {
        line-height: 27px;
    }
    .about p {
        max-width: 100%;
    }
    .countdown-container {
        padding: 0 0 30px;
    }
    .countdown-price {
        text-align: center;
    }
    .p-badd .add-area {
        margin: 0;
    }
    .about-counter-single {
        border-bottom: 0 none;
        margin-bottom: 0;
    }
    .navbar-nav li:first-child a {
        border-top: 0px solid #f5f5f5!important;
    }
    .navbar-nav {
        margin: 6.25px -15px !important;
    }
    .swiper-wrappers li.swiper-slidess {
        width: 50%;
    }
    .swiper-wrappers li.swiper-slidess .product-list-left {
        margin-bottom: 18px;
    }
    .info_menu {
        display: block;
        margin: 35px 0;
        overflow: hidden;
        padding-left: 15px;
    }

    .navbar a.navbar-brand {
        color: #121212;
        font-size: 25px;
        font-weight: 700;
        height: auto;
        margin-bottom: 25px;
        margin-top: 5px;
        padding: 0;
        text-transform: uppercase;
        width: 55%;
    }
    .navbar-header .navbar-toggle {
        margin-right: -10px;
        margin-top: 3px;
        padding-right: 0;
    }
    .navbar .navbar-header {
        margin-top: 0px;
        width: 100%;
    }
    h3, .h3 {
        font-size: 20px;
    }
    .about {
        padding: 7px 0 67px;
    }
    .catalog-area {
        padding-top: 19px;
    }
    .section-min.section.product {
        padding-bottom: 55px;
        padding-top: 0;
    }
    .new-products-area {
        margin-top: -30px;
        padding-top: 0;
    }
    .countdown {
        padding-bottom: 22px;
    }
    .modal-body .product-description {
        width: 100%;
    }
    .modal-dialog .section-heading {
        margin-top: 20px;
    }
    .banner-box.banner-type-5 .banner-info .heading-bigger {
        font-size: 35px;
    }
    .banner-box .banner-title-1 {
        font-size: 14px;
    }
    .banner-box .banner-inner {
        margin-bottom: 29px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-4 {
        font-size: 15px;
    }
    .banner-box.banner-type-5 .banner-info .banner-title-5 {
        margin-bottom: 0;
    }
    .banner-box.banner-type-5 .btn.btn-style-1 {
        width: 130px;
    }
    .section-heading {
        margin: 26px 0 74px;
    }
    .single-category-box .category-content h3 {
        font-size: 17px;
        margin: 0;
    }
    .single-category-box {
        margin-bottom: 64px;
    }
    .product-list-sliderd {
        margin-bottom: 10px;
    }
    .new-products-area .product-list {
        margin-bottom: 0;
    }
    #testimonials.testimonials {
        margin-top: -60px;
        padding: 0 0 52px;
    }
    .about-counter-single {
        border-right: 0 none;
        padding: 35px 0 0;
    }
    section.section-min.nnrr {
        padding-top: 25px;
        padding-bottom: 0;
    }
    .navbar a.navbar-brand {
        margin-bottom: 22px;
        margin-top: 10px;
    }
    .containers {
    padding: 10px 5% !important;
}
    .navbar-header .navbar-toggle {
        margin-top: 0;
    }
    .searchBar {
        display: none;
    }













}
.bright {
    color: #2c96ea;
}
.nowshow, .nowshows, .nowshowm {
    font-size: 30px !important;
}
.ml-3, .mx-3 {
    margin-left: 1rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
select#basic-select {
    font-size: 15px;
    padding: 0 25px;
    height: 28px;
    top: 0;
    position: absolute;
    background: transparent;
    border-radius: 3px;
}
.nowshowm.d-none, .close-filter.d-none {
    display: none;
}
.listItem div {
    float: left;
    position: relative;
    z-index: 99;
}
.listItem {
    width: 100%;
    float: left;
    margin-bottom: 30px;
}
@media (max-width: 800px) {
    .d-sm-none {
        display: none !important;
    }
    div#filter-hide {
        position: fixed;
        height: 100%;
        top: 0px;
        left: 0;
        overflow-y: scroll;
        z-index: 9999;
        width: 70%;
        background: #fff;
        padding: 20px 5px !important;
        box-shadow: 0 0 black;
    }
        .d-sm-block {
        display: block !important;
    }
    .catSide li .linkCat {
        background: #fff !important;
    }
    .catSide li .linkCat.actives {
        background: #2d97ea !important;
        color: #fff !important;
    }
    .close-filter {
    margin-bottom: 10px;
}
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .container {
        width:100%;
    }
    .extrbar.d-xs-block {
        display: block !important;
        padding-top: 85px !important;
        background: #fff;
        padding: 10px;
    }
    .searchBar {
        display: none;
    }
    .navbar {
        padding: 0px 0px;
        margin: 0;
    }
    .swiper-slide h1 {
        font-size: 90px;
    }
    .swiper-wrapper .btn-primary {
        width: 40%;
    }
    .product-list-vertical .product-list-right {
        text-align: left;
    }
    .product-list li {
        margin-bottom: 15px;
        text-align: center;
    }
    .countdown-counter li{
        width:100%

    }
    .countdown-container {
        text-align: center;
    }
    .countdown-container .btn.btn-default.add-item, .btn.btn-default.btn-lg, span.product-right-section .btn.btn-default.add-item, .contact-form button.btn {
        width: auto;
    }
    .product-list li span {
        float: inherit !important;
    }
    .footer-newsletter .form-control {
        height: 48px;
    }
    .swiper-wrappers li.swiper-slidess {
        width: 100%;
    }
    .swiper-wrappers li.swiper-slidess .product-list-left {
        margin-bottom: 18px;
    }
    .f_logo img {
        width: 45%;
    }
    .navbar-brand img {
        margin-top: 0px;
        width: 200px;
    }
    .navbar-header .navbar-toggle {
        margin-top: 3px;
    }


}

